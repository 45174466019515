﻿$(document).ready(function() {
    $('.js-icon-user').click(function (e) {
        e.preventDefault();
        const el = $(this);

        if (el.hasClass('active')) {
            closeDropdown();
        } else {
            openDropdown();
        }
    });
    
    function openDropdown() {
        $('.header__user-menu__open-link.inline-popup-link').trigger('click');
        $('.js-icon-user').addClass('active');
    }

    function closeDropdown() {
        $('.header__user-menu__open-link.inline-popup-link').trigger('click');
        $('.js-icon-user').removeClass('active');
    }
});