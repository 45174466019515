$(document).ready(() => {
    $(".inline-popup-link").click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        let dropdownLink = $(e.currentTarget),
            $dropDown = dropdownLink.next('.inline-popup');

        if (dropdownLink.hasClass('open')) {
            dropdownLink.removeClass('open');
            $dropDown.removeClass('inline-popup_opened');
            $('.page-wrap').removeClass('overflowed');
        } else {
            $('.inline-popup-link').removeClass('open');
            $('.inline-popup').removeClass('inline-popup_opened');
            dropdownLink.addClass('open');
            $dropDown.addClass('inline-popup_opened');
            $('.page-wrap').addClass('overflowed');
        }
        
    });

    $(".close-popup").click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        let dropdownLink = $(e.currentTarget),
            $dropDown = dropdownLink.closest('.inline-popup');

        if (dropdownLink.hasClass('open')) {
            dropdownLink.removeClass('open');
            $dropDown.removeClass('inline-popup_opened');
            $('.page-wrap').removeClass('overflowed');
        }

    });

    $(".new-message__block").click(function (e) {
        e.preventDefault();
        e.stopPropagation();

        $('#dropdownBellMenu').click();
    });

    $(".js-workhours-details-toggle").on("click", (() => {
        $(".js-contacts-workhours").toggleClass("active");
        $(".js-workhours-chevron-status").toggleClass("open")
    }));

    $(".js-mobile-ham").on("click", (() => {
        $(".menu-mobile__container").toggleClass("active");
        $(".js-mobile-ham").toggleClass("open")
    }));
})
