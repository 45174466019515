import MicroModal from "micromodal";

function getCookie(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
}

$(document).ready(function () {
    if (getCookie("AuthorizationCallback")) {
        const day = new Date().getDate();
        if (day >= 4 && day <= 8) {
            //MicroModal.show('site-on-maintenance-modal');
        }
        deleteCookie("AuthorizationCallback");
    }
});