﻿$(document).ready(function() {

    $('.js-mobile-ham').click(function (e) {
        e.preventDefault();
        const el = $(this);
        
        showTopLevelItems();
        hideInnerMenus();
        hideMobileBreadcrumb();
    
        hideProjectsMenu();
        $('.js-project-menu-open')
            .show();
    
        if (el.hasClass('active')) {
            closeDropdown();
        } else {
            openDropdown();
        }
    });
    
    $('.js-project-menu-open').click(function (e) {
        e.preventDefault();
    
        const el = $(this);
        const breadcrumbText = el.attr('data-title');
    
        el.hide();
    
        hideTopLevelItems();
        hideInnerMenus();
    
        $('.js-header-projects-menu')
            .addClass('header__main-menu_projects_active');
        setBreadcrumbText(breadcrumbText);
    
        showMobileBreadcrumb();
    });
    
    $('.js-header-main-menu-breadcrubs-first-item').click(function (e) {
        showTopLevelItems();
        hideInnerMenus();
        hideMobileBreadcrumb();
        hideProjectsMenu();
        $('.js-project-menu-open').show();
    });
    
    function showTopLevelItems() {
        $('.js-header-main-menu-top-level-li')
            .removeClass('header__main-menu__top-level-item_hidden');
    }
    
    function hideTopLevelItems() {
        $('.js-header-main-menu-top-level-li')
            .addClass('header__main-menu__top-level-item_hidden');
    }
    
    function hideInnerMenus() {
        $('.js-header-main-menu-popup').hide();
    }
    
    function setBreadcrumbText(text) {
        $('.js-header-main-menu-breadcrubs-item-text').text(text);
    }
    
    function showMobileBreadcrumb() {
        $('.js-header-main-menu-breadcrubs-item')
            .addClass('header__main-menu__breadcrumbs__item_visible');
        $('.js-header-main-menu-breadcrubs-first-item')
            .addClass('header__main-menu__breadcrumbs__item_inactive');
    }
    
    function hideMobileBreadcrumb() {
        $('.js-header-main-menu-breadcrubs-item')
            .removeClass('header__main-menu__breadcrumbs__item_visible');
        $('.js-header-main-menu-breadcrubs-first-item')
            .removeClass('header__main-menu__breadcrumbs__item_inactive');
    }
    
    function hideProjectsMenu() {
        $('.js-header-projects-menu')
            .removeClass('header__main-menu_projects_active');
    }
    
    function openDropdown() {
        $('.js-mobile-ham')
            .addClass('active');
        $('.js-header-main-menu')
            .addClass('header__main-menu_open');
    }
    
    function closeDropdown() {
        $('.js-header-main-menu')
            .removeClass('header__main-menu_open');
        $('.js-mobile-ham')
            .removeClass('active');
    
        $('.js-header-main-menu-top-level-li')
            .removeClass('active');
        hideInnerMenus();
        hideProjectsMenu();
    }
});